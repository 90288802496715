<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your Customer Overview</h1>
        <div
            class="flex align-items-center justify-content-between sub-navigation"
        >
            <div class="grouped-buttons">
                <button
                    class="grouped-button-left button-gray-bg selected"
                    [routerLink]="['/collections']"
                >
                    All <span>{{ collections.length }}</span>
                </button>
                <button [disabled]="!noOfPinnedCollections" [className]="!noOfPinnedCollections ? 'disable-button' : 'grouped-button-right button-gray-bg'" [routerLink]="['/collections/pinned']">Pinned
                    <span>{{ noOfPinnedCollections }}</span></button>
            </div>
            <cc-filter-and-search
                [dataToFilter]="collections"
                [filterOptions]="collectionFiltersOptions"
                [category]="category"
                (onFilterChanged)="onFilterChangedHandler($event)"
            ></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="collections.length">
            <div
                class="col-12 xl:col-4"
                *ngFor="let collectionCard of collections"
            >
                <cc-entity-card
                    [entity]="collectionCard"
                    [entityType]="EntityType.COLLECTION"
                    [entityStatus]="entityStatus"
                    [entityOverriddenStatuses]="entityOverriddenStatuses"
                    [selectedSite]="selectedSite"
                    (cardPinToggle)="onCardPinToggle($event)"
                ></cc-entity-card>
            </div>
        </div>

        <div class="grid" *ngIf="!collections.length">
            <div class="col-12">
                <h2>No Collections found</h2>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
