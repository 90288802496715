<div class="entity-card" [routerLink]="['/', entityType.toLowerCase() + 's', entity?.id]">
    <div class="entity-card-content">
        <div class="flex flex-row justify-content-between align-items-center w-full mb-3">
            <div>
                <img ngSrc="assets/svg/{{entity?.classIcon}}.svg" width="48" height="48" alt="class icon">
            </div>


            <div class="flex flex-row gap-3 align-items-center h-full mr-3">
                <!--OFFLINE device-->
                <div class="flex align-items-center cursor-auto" *ngIf="isEntityOffline(entity?.id)">
                    <img ngSrc="assets/svg/DQ.svg" height="22" width="22"
                         pTooltip="This determines the data points are missing and / or flatlining. Please check in detailed view and history graph."
                         alt="data points are missing or flatlining"/>
                </div>

                <!--ALERTS count-->
                <div class="flex align-items-center alert-badge cursor-auto"
                     [ngClass]="getAlertBadgeClass(filteredSmartAlerts)">
                    <i class="pi pi-bell"
                       pTooltip="This determines the priority and number of active smart alerts. Please check in detailed view, under Smart Alerts sub section."
                    ></i>
                    <strong *ngIf="filteredSmartAlerts?.length > 0">
                        &nbsp; {{ filteredSmartAlerts?.length }}
                    </strong>
                </div>
                <!--overridden point - lock button/icon -->
                <div *ngIf="isEntityOverridden(entity?.id)">
                    <i class="pi pi-lock is-overridden cursor-auto" pTooltip="This determines the overridden points. Please check in detail view."></i>
                </div>
                <div>
                    <eon-ui-icon *ngIf="entity?.pinned" class=" cursor-pointer"
                                 name="bookmark"
                                 (click)="togglePin($event, entity)"></eon-ui-icon>
                    <eon-ui-icon *ngIf="!entity?.pinned" class=" cursor-pointer"
                                 name="bookmark_outline"
                                 (click)="togglePin($event, entity)"></eon-ui-icon>
                </div>
            </div>
        </div>

        <div class="entity-details">
            <h2
                class="entity-name"
                [pTooltip]="entity?.label"
                [tooltipDisabled]="
                                    entity?.label &&
                                    entity?.label?.length <= 30
                                "
                tooltipPosition="top"
                [hideDelay]="100"
            >
                {{ entity?.label }}
            </h2>
            <p class="entity-class">{{ entity?.classLabel }}</p>
        </div>


    </div>
    <div class="entity-card-footer">
        <div class="entity-kpi flex align-items-center justify-content-around" *ngIf="entity?.selectedKpi?.signalType">
            <ng-container [ngSwitch]="entity?.selectedKpi?.signalType">
                <ng-container *ngSwitchCase="'Binary'">
                                    <span class="margin-right-05">{{
                                            entity?.selectedKpi?.comment ||
                                            entity?.selectedKpi?.classLabel
                                        }}</span>
                    <p-inputSwitch styleClass="default-switch__black" [disabled]="true"
                                   [(ngModel)]="entity.selectedKpi.presentValue"></p-inputSwitch>
                </ng-container>
                <ng-container *ngSwitchDefault>
                                    <span class="margin-right-05">{{
                                            entity?.selectedKpi?.comment ||
                                            entity?.selectedKpi?.classLabel
                                        }}</span>
                    <span class="kpi-value">
                                        {{ entity?.selectedKpi?.presentValue }}
                        <span style="font-size:1rem"
                              [innerHTML]="entity?.selectedKpi?.htmlSymbol"></span>
                                    </span>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
