<p-toast
    position="top-right"
    [breakpoints]="{ '768px': { width: '100%', right: '0', left: '0' } }"
></p-toast>

<div appVhMobileFix id="app-wrapper" class="app-wrapper flex flex-column">
    <header class="app-header">
        <div class="container header flex justify-content-between align-items-center">
            <div class="main-nav-link mobile-navigation">
                <p-button
                    class="burger-menu"
                    type="text"
                    icon="pi pi-bars"
                    (click)="openBurgerMenuOnMobile()"
                ></p-button>
            </div>
            <div class="logo flex">
                <img
                    src="/assets/png/logo.png"
                    title="Optimum C&C Dashboard"
                    alt="Optimum C&C Dashboard"
                />
                <span>Connect and Control</span>
            </div>
            <div class="main-nav-link desktop-navigation">
                <a
                    href="#"
                    [routerLink]="['equipments']"
                    routerLinkActive="selected"
                    >Engineering</a
                >
                <a
                    href="#"
                    [routerLink]="['locations']"
                    routerLinkActive="selected"
                    >Locations</a
                >
                <a
                    href="#"
                    [routerLink]="['collections']"
                    routerLinkActive="selected"
                    >Customer Overview</a
                >
                <a
                    href="#"
                    [routerLink]="['customer-specification']"
                    routerLinkActive="selected"
                    >Customer Spec</a
                >
            </div>
            <cc-site-filter *ngIf="user"></cc-site-filter>
            <eon-ui-icon
                class="cursor-pointer"
                (click)="logoutOverlay.toggle($event)"
                name="user"
                size="big"
                scheme="white"
            ></eon-ui-icon>

            <p-overlayPanel
                styleClass="logout-overlay"
                #logoutOverlay
                appendTo="body"
            >
                <ng-template pTemplate>
                    <p>
                        Logged in as: <b>{{ user }}</b>
                    </p>
                    <eon-ui-button
                        size="small"
                        scheme="turquoise"
                        text="Logout"
                        (click)="logout()"
                    ></eon-ui-button>
                </ng-template>
            </p-overlayPanel>
        </div>
    </header>
    <main class="flex-grow-1">
        <router-outlet></router-outlet>
    </main>
    <footer aria-label="footer-bar">
        <div class="eonui-footer-content-wrapper">
            <div class="eonui-footer-wrapper">
                <div class="eonui-link-content">
                    <div class="eonui-socialmedia">
                        <slot name="socialmedia"></slot>
                    </div>
                    <div class="eonui-links">
                        <slot name="links"></slot>
                    </div>
                </div>
                <div class="eonui-logo-content">
                    <a target="_self" href="/terms-and-conditions"
                        >Terms of Use</a
                    >
                    <div class="eonui-copyright-content">
                        <span class="eonui-copyright">© E.ON SE 2022</span>
                    </div>
                    <div class="eonui-logo">
                        <div
                            class="eonui-image-caption-container"
                            style="display: table"
                        >
                            <div class="eonui-image-container">
                                <figure>
                                    <picture>
                                        <source
                                            srcset="/assets/png/eon-logo.png"
                                            media="(min-width: 1024px) AND (max-width: 1279px)"
                                        />
                                        <img
                                            alt="E.ON Logo"
                                            src="/assets/png/eon-logo.png"
                                            style="
                                                object-position: center center;
                                                width: 100px;
                                            "
                                        />
                                    </picture>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <eon-ui-color-separator class="hydrated"></eon-ui-color-separator>
    </footer>
</div>
<div
    class="slide-out-box-wrapper"
    [ngClass]="isBurgerMenuActive ? 'isActive' : ''"
>
    <eon-ui-button
        class="close-button-top-right"
        icon="close"
        size="small"
        scheme="discreet"
        (click)="closeBurgerMenuOnMobile()"
    ></eon-ui-button>

    <div
        class="slide-out-box-body slide-out-box-body__no-padding slide-out-box-body__full-height"
    >
        <ul class="cbms-menu-container">
            <li class="cbms-menu-item">
                <a
                    href=""
                    [routerLink]="['equipments']"
                    routerLinkActive="selected"
                    (click)="closeBurgerMenuOnMobile()"
                    >Equipment</a
                >
            </li>
            <li class="cbms-menu-item">
                <a
                    href=""
                    [routerLink]="['locations']"
                    routerLinkActive="selected"
                    (click)="closeBurgerMenuOnMobile()"
                    >Locations</a
                >
            </li>
            <li class="cbms-menu-item">
                <a
                    href=""
                    [routerLink]="['collections']"
                    routerLinkActive="selected"
                    (click)="closeBurgerMenuOnMobile()"
                    >Collections</a
                >
            </li>
            <li class="cbms-menu-item">
                <a
                    href=""
                    [routerLink]="['customer-specification']"
                    routerLinkActive="selected"
                    (click)="closeBurgerMenuOnMobile()"
                    >Customer Spec</a
                >
            </li>
        </ul>
    </div>
</div>
