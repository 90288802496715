import { Component, Input, OnInit } from "@angular/core";
import { SmartAlert } from "../../models/dashboard";
import { DialogService } from "primeng/dynamicdialog";
import { SmartAlertDetailsComponent } from "../../dialogs/smart-alert-details/smart-alert-details.component";
import { BackendService } from "src/app/services/backend/backend.service";
import {
    Bell,
    IUpdateSmartAlertReq,
    RolePermissionListRes,
    SmartAlertStatusLabel,
} from "src/app/models/core/alert-rule.model";
import { NotificationService } from "src/app/services/notification/notification.service";
import { IN_PROGRESS, USER_ROLES } from "src/app/config/constants";
import { SmartAlertCodePipe } from "src/app/pipes/smart-alert-code.pipe";
import { AlertStatusLabelPipe } from "src/app/pipes/alert-status-label.pipe";
import { CloseAlertConfirmationPopupComponent } from "src/app/popups/confirmation-popup/close-alert-confirmation-popup.component";
import { UtilsService } from "src/app/services/utils/util.service";

@Component({
    selector: "cc-smart-alerts",
    templateUrl: "./smart-alerts.component.html",
    styleUrl: "./smart-alerts.component.scss",
})
export class SmartAlertsComponent implements OnInit {
    @Input()
    alerts: SmartAlert[] = [];
    alertStatusList: string[];
    userHasEditPermission: boolean;
    readonly bellList: Bell[] = [
        {
            className: "alert-high-priority",
            description: "Alert with priority type as High",
            alertNum: 2,
        },
        {
            className: "alert-medium-priority",
            description: "Alert with priority type as Medium",
            alertNum: 2,
        },
        {
            className: "alert-low-priority",
            description: "Alert with priority type as Low",
            alertNum: 2,
        },
        {
            className: "no-alerts",
            description: "No active alert",
        },
    ];

    constructor(
        private dialogService: DialogService,
        private backendService: BackendService,
        private alertStatusLabelPipe: AlertStatusLabelPipe,
        private smartAlertCodePipe: SmartAlertCodePipe,
        private utilServ: UtilsService
    ) {}

    ngOnInit(): void {
        this.alertStatusList = [
            SmartAlertStatusLabel.OPEN,
            SmartAlertStatusLabel.CLOSED,
            SmartAlertStatusLabel.IN_PROGRESS,
        ];
        this.backendService
            .getUserRolePermissionList()
            .subscribe((response: RolePermissionListRes) => {
                this.userHasEditPermission =
                    response.userRole.includes(USER_ROLES.ROLE_GLOBAL_ADMIN) ||
                    response.userRole.includes(USER_ROLES.ROLE_CUSTOMER_ADMIN);
            });
        this.getSmartAlertStatusLabel();
    }

    /**
     * @description convert smart alert status code to status label
     * @input none
     * @output void
     */
    getSmartAlertStatusLabel() {
        this.alerts.map((alert) => {
            alert.statusLabel = this.alertStatusLabelPipe.transform(
                alert.status
            );
        });
    }

    get alertsToRender() {
        return this.alerts.filter((alert) =>
            ["OPEN", "IN_PROGRESS"].includes(alert.status)
        );
    }

    showAlertDetails(alertId: string) {
        this.dialogService.open(SmartAlertDetailsComponent, {
            data: { id: alertId },
            header: "Smart Alert Details",
            contentStyle: { padding: "0" },
            width: "70%",
            closable: false,
        });
    }

    /**
     * @description update smart alert status
     * @input status
     * @output void
     */
    updateSmartAlertStatus(alertObj: SmartAlert, selectedStatus: any) {
        const param: IUpdateSmartAlertReq = {
            alertId: alertObj?.alertUUID,
            alertStatus: this.smartAlertCodePipe.transform(
                selectedStatus.value
            ),
        };

        // if user try to close a smart alert, a confirmation popup will appear
        if (selectedStatus.value === SmartAlertStatusLabel.CLOSED) {
            const dialogRef = this.dialogService.open(
                CloseAlertConfirmationPopupComponent,
                {
                    data: {
                        param: param,
                        oldStatusCode: alertObj.status,
                    },
                    header: "Edit Smart Alert: Change Status to Closed",
                    contentStyle: { padding: "0" },
                    width: "35%",
                    closable: true,
                }
            );

            dialogRef.onClose.subscribe(
                (result: { currentAlertStatus: string }) => {
                    this.updateAlertList(alertObj, result?.currentAlertStatus);
                }
            );
        } else {
            this.backendService.updateStatusForSmartAlert(param).subscribe(
                (res) => {
                    this.updateAlertList(alertObj, param.alertStatus);
                    this.utilServ.notifyUpdateSmartAlertSuccessfully(
                        param.alertStatus
                    );
                },
                (err) => {
                    this.updateAlertList(alertObj);
                    this.utilServ.notifyUpdateSmartAlertFailed();
                }
            );
        }
    }

    /**
     * @description update status (status code, status label) of the modified smart alert in the list
     * @param originalAlertObj
     * @param newAlertStatus
     * @output void
     */
    updateAlertList(originalAlertObj: SmartAlert, newAlertStatus?: string) {
        let updatedAlert = this.alerts.find(
            (item) => item.alertUUID === originalAlertObj.alertUUID
        );
        if (newAlertStatus) {
            updatedAlert.status = newAlertStatus;
            updatedAlert.statusLabel =
                this.alertStatusLabelPipe.transform(newAlertStatus);
        } else {
            updatedAlert.status = originalAlertObj.status;
            updatedAlert.statusLabel = this.alertStatusLabelPipe.transform(
                originalAlertObj.status
            );
        }
    }
}
