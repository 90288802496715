<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your Engineering</h1>
        <div class="flex align-items-center justify-content-between sub-navigation">
            <div class="grouped-buttons">
                <button class="grouped-button-left button-gray-bg selected" [routerLink]="['/equipments']">All
                    <span>{{ equipments?.length }}</span></button>
                <button [disabled]="!noOfPinnedEquipments" [className]="!noOfPinnedEquipments ? 'disable-button' : 'grouped-button-right button-gray-bg'" [routerLink]="['/equipments/pinned']">Pinned
                    <span>{{ noOfPinnedEquipments }}</span></button>
            </div>
            <cc-filter-and-search [dataToFilter]="equipments" [filterOptions]="equipmentFiltersOptions"
                                  [category]="category"
                                  (onFilterChanged)="onFilterChangedHandler($event)"></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="equipments?.length">
            <div class="col-12 xl:col-4" *ngFor="let equipmentCard of equipments">
                <cc-entity-card [entity]="equipmentCard"
                                [entityOverriddenStatuses]="entityOverriddenStatuses"
                                [entityType]="EntityType.EQUIPMENT"
                                [entityStatus]="equipmentStatus"
                                [selectedSite]="selectedSite"
                                (cardPinToggle)="onCardPinToggle($event)"></cc-entity-card>
            </div>
        </div>

        <div class="grid" *ngIf="!equipments?.length">
            <div class="col-12">
                <h2>No equipments found</h2>
            </div>
        </div>

    </div>

    <router-outlet></router-outlet>
</div>
