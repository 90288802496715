<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your Locations</h1>
        <div class="flex align-items-center justify-content-between sub-navigation">
            <div class="grouped-buttons">
                <button class="grouped-button-left button-gray-bg selected" [routerLink]="['/locations']">All
                    <span>{{ locations.length }}</span></button>
                <button [disabled]="!noOfPinnedLocations" [className]="!noOfPinnedLocations ? 'disable-button' : 'grouped-button-right button-gray-bg'" [routerLink]="['/locations/pinned']">Pinned
                    <span>{{ noOfPinnedLocations }}</span></button>
            </div>
            <cc-filter-and-search [dataToFilter]="locations" [filterOptions]="locationFiltersOptions"
                                  [category]="category"
                                  (onFilterChanged)="onFilterChangedHandler($event)"></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="locations.length">
            <div class="col-12 xl:col-4" *ngFor="let locationCard of locations">
                <cc-entity-card [entity]="locationCard"
                                [entityOverriddenStatuses]="entityOverriddenStatuses"
                                [entityType]="EntityType.LOCATION"
                                [entityStatus]="entityStatus"
                                [selectedSite]="selectedSite"
                                (cardPinToggle)="onCardPinToggle($event)"></cc-entity-card>
            </div>
        </div>

        <div class="grid" *ngIf="!locations.length">
            <div class="col-12">
                <h2>No locations found</h2>
            </div>
        </div>

    </div>

    <router-outlet></router-outlet>
</div>
