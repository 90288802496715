<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your Pinned Engineering</h1>
        <div
            class="sub-navigation flex justify-content-between align-items-center"
        >
            <ng-container>
                <div class="grouped-buttons">
                    <button class="grouped-button-left button-gray-bg" [routerLink]="['/equipments']">All
                        <span></span></button>
                    <button class="grouped-button-right button-gray-bg selected"
                            [routerLink]="['/equipments/pinned']">Pinned <span>{{ noOfPinnedEquipments }}</span>
                    </button>
                </div>

                <div class="grouped-buttons desktop-navigation">
                    <button class="grouped-button-left button-gray-bg"
                            [ngClass]="cardType === 'summary' ? 'selected' : ''" (click)="showSummary()">Summary
                    </button>
                    <button class="grouped-button-right button-gray-bg"
                            [ngClass]="cardType === 'detailed' ? 'selected' : ''" (click)="showDetailed()">Detailed
                    </button>
                </div>

                <div class="mobile-navigation">
                    <div class="checkbox-switch">
                        <input type="checkbox" checked="true" (change)="toggleCardState($event)" name="status"
                               class="input-checkbox" id="toolbar-active">
                        <div class="checkbox-animate">
                            <span class="checkbox-off">Detailed</span>
                            <span class="checkbox-on">Summary</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="main-wrapper">
    <div class="container no-padding">

        <div class="grid" *ngIf="cardType === 'summary'">
            <div class="col-12 xl:col-4" *ngFor="let equipmentCard of equipments">
                <cc-entity-card [entity]="equipmentCard"
                                [entityType]="EntityType.EQUIPMENT"
                                [entityStatus]="equipmentStatus"
                                [entityOverriddenStatuses]="entityOverriddenStatuses"
                                [selectedSite]="selectedSite"
                                (cardPinToggle)="onCardPinToggle($event)"></cc-entity-card>
            </div>
        </div>

        <p-tabView styleClass="custom-no-bg" *ngIf="cardType === 'detailed'">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/live-view.svg"/>
                    <span>Live View</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12 xl:col-6" *ngFor="let equipmentDetails of equipmentDetailsList">
                        <cc-equipment-details-view [username]="username" [selectedSite]="selectedSite"
                                                   [equipmentDetails]="equipmentDetails" [pointsStatus]="equipmentStatus"></cc-equipment-details-view>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/dashboard.svg"/>
                    <span>History</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12" *ngIf="(pointsObj | json) != '{}'">
                        <cc-historical-data [pointsObj]="pointsObj"></cc-historical-data>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-confirmDialog #cd [style]="{width: '50vw'}" [breakpoints]="{'640px': '90vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end" *ngIf="userHasWritePermission$ | async as userHasWritePermission">
            <eon-ui-button *ngIf="!isOverrideResetOn || !userHasWritePermission" size="small" scheme="turquoise"
                           text="Close" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" class="margin-right-1" size="small"
                           scheme="turquoise" text="No" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" size="small" scheme="turquoise"
                           text="Yes" (click)="cd.accept()"></eon-ui-button>
        </div>
    </ng-template>
</p-confirmDialog>
